import Vue from "vue";
import { createApp } from "vue";
import lodash from "lodash";
// import store from "./store";
import "./index.css";
import ElementPlus from 'element-plus';
import vue3GoogleLogin from 'vue3-google-login'
// import pinia from '@/store/index';

import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist'
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// import '@/permission';

import {userApi} from '@/api/user'
import { Local, Session } from '@/utils/storage';
import App from "./App.vue";
import router from "./router";

import '@/permission';

const userRequestApi = userApi();
if(Session.get('client_id')==null){
    await userRequestApi.getGoogleClientId().then((res:any)=>{
      if(res.code==200){
        let data =res.data
        Session.set('client_id',data['data-client-id'])
        Session.set('login-uri',data['data-login-uri'])
      }
    }).catch((error) => {
        console.log('==load error',error)
    });
  }

const app = createApp(App);
app.use(vue3GoogleLogin,{
    clientId:Session.get('client_id')?Session.get('client_id'):'661299855856-t30j7681372nuna0a2b910j3h9gt4hf7.apps.googleusercontent.com',
    buttonConfig:{
      locale:'en'
    }
})
app.config.globalProperties._=lodash;
const store = createPinia()
store.use(piniaPersist)
app.use(store).use(router).use(ElementPlus).mount("#app");
// import '@/permission';

