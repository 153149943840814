import { defineStore } from 'pinia';
import { Session,Local } from '@/utils/storage';
import { userApi } from '@/api/user';


/**
 * 用户信息
 * @methods setUserInfos 设置用户信息
 */
export const useUserInfo = defineStore('userInfo', {
	state: (): UserInfosState => ({
		userInfos: {
            id:0,
			name: '',
			email: '',
			avatar: '',
			planType:'',
            timesLimit:0,
            maxFileSize:0,
            maxCharacteristicSize:0,
            subscriptionDateTime:null,
		},
        login:false
	}),
	actions: {
		async setUserInfos() {
			// 存储用户信息到浏览器缓存
			if (Session.get('userInfo')) {
				this.userInfos = Session.get('userInfo');
			} else {
				const userInfos = <UserInfos>await this.getApiUserInfo();
				this.userInfos = userInfos;
			}
		},
		async getApiUserInfo() {
			return new Promise((resolve,reject) => {
				userApi().getUserInfo().then((res:any)=>{
					const userInfos = {};
					if(res.code==200){
						const userInfos =res.data;
						Session.set('userInfo', userInfos);
						resolve(userInfos);
					}else{//已下线
					}
				}).catch((error)=>{
					console.log('===get user info fail:',error)
				});
			});
		},
		async reset(){
			this.login =false;
			this.userInfos = {
				id:0,
				name: '',
				email: '',
				avatar: '',
				planType:'',
				timesLimit:0,
				maxFileSize:0,
				maxCharacteristicSize:0,
				subscriptionDateTime:null,
			}
		}
	},
});
