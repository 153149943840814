<template>
  <router-view />
</template>

<script setup lang="ts" name="app">
import { onMounted } from 'vue'
// import {userApi} from '@/api/user'
// import { Local, Session } from '@/utils/storage';
// const userRequestApi = userApi();

onMounted(()=>{
//   if(Session.get('client_id')==null){
//     userRequestApi.getGoogleClientId().then((res:any)=>{
//       if(res.code==200){
//         let data =res.data
//         Session.set('client_id',data['data-client-id'])
//         Session.set('login-uri',data['data-login-uri'])
//       }
//     }).catch(() => {
      
//     });
//   }
})
</script>
<style lang="scss">
#app {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251/var(--tw-bg-opacity))
}
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
