import request from '@/utils/request';
// import axios from 'axios';


export function userApi() {
	return {
		getGoogleClientId:()=>{
			return request({
				url:'/api/load-google-data.action',
				method:'get',
			})
		},
        getUserInfo:()=>{
            return request({
                url:'/api/auth/load-user-info.action',
                method:'get'
            })
        },
		googleLoginCallback:(data:object)=>{
			return request({
                url:'/api/login-google.action',
                method:'post',
				data
            })
		},
		logout:()=>{
			return request({
				url:'/api/auth/sign-out.action',
				method:'get'
			})
		}
	};
}