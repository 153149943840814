<template>
    <Sidebar></Sidebar>
    <main class="lg:pl-72 min-h-screen">
      <router-view :key="key"></router-view>
    </main>
</template>
<script setup lang="ts" name="DashboardLayout">
import { useRoute } from "vue-router";
import { defineAsyncComponent, reactive,ref,computed } from "vue";
const Sidebar = defineAsyncComponent(() => import("@/layout/components/sidebar.vue"));
const route = useRoute();
const key = computed(() => {
  return route.path;
});

</script>