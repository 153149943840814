import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Layout from "@/layout/index.vue";
import Dashboard from '@/layout/dashboard.vue'

export const constRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // // },
  {
      path: "/",
      component: () => import("@/views/ai-detector/index.vue"),
      name: "AiDetector",
      meta: { title: "YesDetect.ai: Trusted AI Detector - Chat GPT Zero Detect" },
  },
  {
      path:'/ai-humanizer',
      component:()=>import("@/views/ai-detector/humanize.vue"),
      name:'humanizeAI',
      meta: { title: "YesDetect.ai: Trusted AI Detector - Chat GPT Zero Detect" },
  },
  {
      path: "/terms",
      component: () => import("@/views/legal/terms.vue"),
      name: "yesDetect-Terms of Service",
      meta: { title: "yesDetect-Terms of Service" },
  },
  {
    path: "/privacy",
    component: () => import("@/views/legal/privacy.vue"),
    name: "yesDetect-Privacy Policy Statement",
    meta: { title: "yesDetect-Privacy Policy Statement" },
  }
  // {
  //   path: "",
  //   component: Layout,
  //   redirect: "/ai-detector",
  //   children: [
  //     {
  //       path: "ai-detector",
  //       component: () => import("@/views/ai-detector/index.vue"),
  //       name: "Ai Detector",
  //       meta: { title: "Ai Detector" },
  //     },
  //   ],
  // }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

export const authRouterMap: Array<RouteRecordRaw> = [
  {
    path:"/dashboard",
    component:Dashboard,
    redirect:"/dashboard/home",
    children:[
      {
        path: "home",
        component: () => import("@/views/dashboard/home.vue"),
        name: "DashboardHome",
        meta: { title: "Home" },
      },
      {
        path: "textDetector",
        component: () => import("@/views/dashboard/textDetector.vue"),
        name: "DashboardTextDetector",
        meta: { title: "Text Detector" },
      },
      {
        path: "fileDetector",
        component: () => import("@/views/dashboard/fileDetector.vue"),
        name: "DashboardFileDetector",
        meta: { title: "File Detector" },
      },
      {
        path: "textHumanizer",
        component: () => import("@/views/dashboard/textHumanizer.vue"),
        name: "DashboardTextHumanizer",
        meta: { title: "Text Humanizer" },
      },
      {
        path: "fileDetectDetail",
        component: () => import("@/views/dashboard/fileDetectDetail.vue"),
        name: "DashboardFileDetectDetail",
        meta: { title: "File Detector Detail"},
      },
      {
        path: "setting",
        component: () => import("@/views/dashboard/setting.vue"),
        name: "DashboardSetting",
        meta: { title: "Setting" },
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes:[...constRoutes,...authRouterMap],
});

export default router;
