import axios from 'axios';
import { ElMessage } from 'element-plus';
import { Local, Session } from '@/utils/storage';


const request = axios.create({
    // baseURL: process.env.NODE_ENV==='development'?"":'https://ai-detector.me',// process.env.VUE_APP_BASE_API,
    // withCredentials: true,
    timeout: 100000,
    headers:{ 'Content-Type': 'application/x-www-form-urlencoded' },
})

request.interceptors.request.use(
    config => {
      // 在发送请求之前做些什么 token
      if (Session.get('token')) {
        config.headers!['Authorization'] = `Bearer ${Session.get('token')}`;
      }
      return config
    },
    error => {
      console.log(error)
      return Promise.reject(error)
    }
  )

  
request.interceptors.response.use(
    response => {
        const res = response.data;
        return res
    },
    error => {
        let {data,code,message} = error

        if(code=='ECONNABORTED'||code=='ERR_BAD_RESPONSE'){
            ElMessage.error({
              message:message,
              plain:true
            })
            return Promise.reject(error);
        }
        else if(code==401){
          	ElMessage.error({
				message:'授权已过期，请重新登陆',
				plain:true
			})
			setTimeout(()=>{
				// Session.clear();
				window.location.reload();
			},1000)
			return Promise.reject(error);
		} else {
			if (error.response.data){
				ElMessage.error({
					message:error.response.statusText,
					plain:true
				})
      		}
			else {
				ElMessage.error({
					message:'接口路径找不到',
					plain:true
				})
			}
			return Promise.reject(error);
		}
    }
  )
  
  export default request
