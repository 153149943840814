import { defineStore } from 'pinia';
import { constRoutes, authRouterMap } from '@/router/index'
import { useUserInfo } from './user';

export const authInfo = defineStore('auth', {
	state: (): RoutesListState => ({
		routesList: [],
    authRouter:[],
    routeGenarate:false
	}),
	actions: {
		async setRoutesList() {
      const userStore = useUserInfo();
      let accessedRouters = [] as any;
			if(userStore.userInfos.id!=0){
        accessedRouters = constRoutes.concat(authRouterMap)
        this.authRouter = authRouterMap;
      }else{
        accessedRouters = constRoutes;
      }
      this.routesList = accessedRouters;
      this.routeGenarate = true;
		},
	},
});
export default authInfo
