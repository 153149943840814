import router from './router'
import getPageTitle from '@/utils/get-page-title'

import { useUserInfo } from '@/store/user'
import { authInfo } from '@/store/auth'

import { Local, Session } from '@/utils/storage';
import { RouteRecordRaw } from 'vue-router';


router.beforeEach(async(to, from, next) => {
  
    document.title = getPageTitle(to.meta.title as string)
  
    const hasToken = Session.get('token')

    const whiteList = ['/','/terms','/privacy'];

    const userStore = useUserInfo();
    const authStore = authInfo()
  
    if (hasToken) {
        if(userStore.userInfos.id!=0){
            next()
        }else{
            try{
                await userStore.setUserInfos();
                if(authStore.routeGenarate==false){
                    authStore.setRoutesList();
                    authStore.authRouter.forEach((item:RouteRecordRaw)=>{
                        router.addRoute(item)
                    })
                    // router.addRoute();
                }
            }catch(error){
                Session.clear()
                if(to.path!='/'){
                    next(`/`)
                }else{
                    next()
                }
            }finally{
            }
        }
        // next
        next({ ...to, replace: true })
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/`)
      }
    }
  })
